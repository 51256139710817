import { Controller } from 'stimulus'
export default class EntryFormController extends Controller {
  static targets = ['body', 'prompt', 'progress', 'progressMeter', 'progressMeterText', 'progressMeterTextWordCount', 'submitButton']
  static promptDelayMilliseconds = 4999
  static encouragementPrompts = [
    'Clear your mind with daily, private, stream-of-consciousness writing to unleash your creative flow.',
    'Type what you are thinking right now.',
    'Get in the flow. Write some words!',
    "Write whatever you want without editing. Doesn't have to be 'good'!",
    'Just write whatever is on your mind.',
    'Whatever I want to accomplish later, getting my mind flowing is the first step.',
    'This is just for you… Write whatever you want.',
    'Fill this page with some words!',
    'Clear out the mind-noise by unleashing some more words!',
    'Want to save without leaving the keyboard? Control-S saves your progress.'
  ]

  static transitionMilliseconds = 500

  connect () {
    this._lastEventTimestamp = new Date().getTime()
    setTimeout(() => { this.updatePrompt() }, EntryFormController.promptDelayMilliseconds)
    this._placeCursorAtEnd()
  }

  updatePrompt () {
    const delay = new Date().getTime() - this._lastEventTimestamp
    if (delay > EntryFormController.promptDelayMilliseconds) {
      this.replacePrompt()
    } else {
      setTimeout(() => { this.updatePrompt() }, 999)
    }
  }

  replacePrompt () {
    this.promptTarget.classList.add('transparent')
    setTimeout(
      () => {
        this.promptTarget.textContent = this.randomPrompt()
        this.promptTarget.classList.remove('transparent')
        this._lastEventTimestamp = new Date().getTime()
        setTimeout(() => { this.updatePrompt() }, EntryFormController.promptDelayMilliseconds)
      },
      EntryFormController.transitionMilliseconds
    )
  }

  randomPrompt () {
    const list = EntryFormController.encouragementPrompts
    return list[Math.floor(Math.random() * list.length)]
  }

  handleKeyPress (event) {
    if (event.ctrlKey && event.key === 's') { this.save() }
  }

  handleInput (event) {
    this._lastEventTimestamp = new Date().getTime()
    this.updateProgressBar()
  }

  save () {
    this.element.submit()
  }

  updateProgressBar () {
    const wordTarget = 750
    const words = this.bodyTarget.value.trim().match(/\S+/g)
    const wordCount = (words && words.length) || 0
    const primaryColor = '#072761'
    const fillColor = primaryColor

    this.progressTarget.setAttribute('aria-valuenow', wordCount)

    this.progressMeterTarget.setAttribute('style', 'width: ' + (Math.min(wordCount / wordTarget, 1) * 100) + '%; background: ' + fillColor)
    if (wordCount < wordTarget) {
      this.submitButtonTarget.value = 'Save'
      this.submitButtonTarget.classList.remove('success')
      if (wordCount > 0) {
        this.progressTarget.setAttribute('style', 'display: block;')
      } else {
        this.progressTarget.setAttribute('style', 'display: none;')
      }
    } else {
      this.submitButtonTarget.value = 'Done'
      this.submitButtonTarget.classList.add('success')
      this.progressTarget.setAttribute('style', 'display: none;')
    }
    this.progressMeterTextWordCountTarget.textContent = wordCount + ' word' + (wordCount !== 1 ? 's' : '')
    this.progressMeterTextTarget.setAttribute('style', 'display: block;')
  }

  _placeCursorAtEnd () {
    const textarea = this.bodyTarget

    textarea.selectionStart = textarea.selectionEnd = textarea.value.length
    textarea.scrollTop = textarea.scrollHeight
  }
}
