import { Controller } from 'stimulus'

export default class LightboxController extends Controller {
  open () {
    document.getElementById('scrim').style.display = 'block'
    document.getElementById('video').src = document.getElementById('video').src.replace('autoplay=0', 'autoplay=1')
  }

  close () {
    document.getElementById('scrim').style.display = 'none'
    document.getElementById('video').src = document.getElementById('video').src.replace('autoplay=1', 'autoplay=0')
  }
}
