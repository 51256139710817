import { Controller } from 'stimulus'

export default class LandingController extends Controller {
  connect () {
    window.location = this.entryUrl()
  }

  entryUrl () {
    const date = new Date()
    return '/entries/' + this.dateString(date) + '?' + this.offsetQueryParam(date)
  }

  dateString (date) {
    const year = date.getFullYear()

    let month = date.getMonth() + 1
    if (month.toString().length < 2) { month = '0' + month }

    let dayOfMonth = date.getDate()
    if (dayOfMonth.toString().length < 2) { dayOfMonth = '0' + dayOfMonth }

    return year + '-' + month + '-' + dayOfMonth
  }

  offsetQueryParam (date) {
    return 'tzo=' + date.getTimezoneOffset()
  }
}
